import Swiper from 'swiper';

export default function mySwiper() {
  const topB = $('#topB');
  /* eslint-disable no-unused-vars */
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    speed: 1000,
    // effect: 'fade',
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    on: {
      slideChange: function () {
        topB.addClass('slide');
        setTimeout(() => {
          topB.removeClass('slide');
        }, 1500);
      },
    },
  });
  /* eslint-enable no-unused-vars */
}

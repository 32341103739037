/* eslint-disable no-unused-vars, no-undef */
export default function formValidate() {
  $('form').on('submit', function (e) {
    e.preventDefault();

    const $form = $(this);
    const $button = $form.find('button');

    //エラーの初期化
    $('.error-state').removeClass('error-state');
    $('p.error-message').remove();

    $('input,textarea')
      .filter('.validate')
      .each(function () {
        //必須項目のチェック
        $(this)
          .filter('.required')
          .each(function () {
            if ($(this).val() == '') {
              $(this).addClass('error-state');
              $(this).parent().append("<p class='error-message'>必須項目です</p>");
            }
          });

        //数値のチェック
        $(this)
          .filter('.number')
          .each(function () {
            if (isNaN($(this).val())) {
              $(this).addClass('error-state');
              $(this).parent().append("<p class='error-message'>数値のみ入力可能です</p>");
            }
          });

        //電話番号のチェック
        $(this)
          .filter('.tel')
          .each(function () {
            if (
              $(this).val() &&
              !$(this)
                .val()
                .replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, '')
                .match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)
            ) {
              $(this).addClass('error-state');
              $(this).parent().append("<p class='error-message'>電話番号の形式が異なります</p>");
            }
          });

        //メールアドレスのチェック
        $(this)
          .filter('.email')
          .each(function () {
            if (
              $(this).val() &&
              !$(this)
                .val()
                .match(/.+@.+\..+/g)
            ) {
              $(this).addClass('error-state');
              $(this).parent().append("<p class='error-message'>メールアドレスの形式が異なります</p>");
            }
          });

        //メールアドレス確認のチェック
        $(this)
          .filter('.email_check')
          .each(function () {
            if (
              $(this).val() &&
              $(this).val() !=
                $(
                  'input[name=' +
                    $(this)
                      .attr('name')
                      .replace(/^(.+)_check$/, '$1') +
                    ']'
                ).val()
            ) {
              $(this).addClass('error-state');
              $(this).parent().append("<p class='error-message'>メールアドレスと内容が異なります</p>");
            }
          });

        //電話番号のチェック
        $(this)
          .filter('.postcode')
          .each(function () {
            if (
              $(this).val() &&
              !$(this)
                .val()
                .replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, '')
                .match(/^[0-9]{3}-[0-9]{4}$/)
            ) {
              $(this).addClass('error-state');
              $(this).parent().append("<p class='error-message'>郵便番号の形式が異なります</p>");
            }
          });
      });

    //ラジオボタンのチェック
    // $(':radio')
    //   .filter('.validate')
    //   .each(function () {
    //     $(this)
    //       .filter('.required')
    //       .each(function () {
    //         if ($('input[name=' + $(this).attr('name') + ']:checked').length == 0) {
    //           $(this).addClass('error-state');
    //           $(this).parent().append("<p class='error-message'>選択してください</p>");
    //         }
    //       });
    //   });

    //チェックボックスのチェック
    $('.checkboxRequired').each(function () {
      if ($(':checkbox:checked', this).length == 0) {
        $(this).append("<p class='error-message'>選択してください</p>");
      }
    });

    // その他項目のチェック
    $('.validate.add_text').each(function () {
      if (
        $(this).attr('checked') == true &&
        $(
          'input[name=' +
            $(this)
              .attr('name')
              .replace(/^(.+)$/, '$1_text') +
            ']'
        ).val() == ''
      ) {
        $(this).addClass('error-state');
        $(this).parent().append("<p class='error-message'>その他の項目を入力してください。</p>");
      }
    });

    //エラーの際の処理
    if ($('p.error-message').length > 0) {
      $('html,body').animate({ scrollTop: $('p.error-message:first').offset().top - 85 }, 'slow');
      $('p.error-message').parent().addClass('error-wrap');
      return false;
    } else {
      // 送信
      $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        // data: $form.serialize() + '&delay=1',
        data: $form.serialize(),
        timeout: 10000, // 単位はミリ秒

        // 送信前
        beforeSend: function (xhr, settings) {
          // ボタンを無効化し、二重送信を防止
          $button.attr('disabled', true);
        },
        // 応答後
        complete: function (xhr, textStatus) {
          // ボタンを有効化し、再送信を許可
          $button.attr('disabled', false);
        },

        // 通信成功時の処理
        success: function (result, textStatus, xhr) {
          // 入力値を初期化
          $form.remove();

          $('#formResult').fadeIn();
          ga('send', 'event', { eventCategory: 'Contact', eventAction: 'ContactRequest' });
        },

        // 通信失敗時の処理
        error: function (xhr, textStatus, error) {},
      });
    }
  });
}
/* eslint-enable no-unused-vars, no-undef */

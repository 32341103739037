import mySwiper from './modules/mySwiper';
import formValidate from './modules/formValidate';

/*
 *  document ready
 */
$(document).ready(() => {
  setTimeout(() => {
    $('body').addClass('is-ready');
  }, 300);

  mySwiper();
  formValidate();
  // ページプラグインの埋め込みコードを返す
  function pagePluginCode(w) {
    // 書き換えたStep3のコードを「return」に記述
    return (
      '<div class="fb-page" data-href="https://www.facebook.com/ueno.kanji.h/?modal=admin_todo_tour" data-tabs="timeline" data-width="' +
      w +
      '" data-height="460" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/ueno.kanji.h/?modal=admin_todo_tour" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ueno.kanji.h/?modal=admin_todo_tour">上野かんじ</a></blockquote></div>'
    );
  }

  // ページプラグインを追加する要素
  var facebookWrap = $('.facebook-wrapper');
  var fbBeforeWidth = ''; // 前回変更したときの幅
  var fbWidth = facebookWrap.width(); // 今回変更する幅
  var fbTimer = false;
  $(window).on('load resize', function () {
    if (fbTimer !== false) {
      clearTimeout(fbTimer);
    }
    fbTimer = setTimeout(function () {
      fbWidth = Math.floor(facebookWrap.width()); // 変更後の幅を取得し、小数点以下切り捨て
      // 前回の幅から変更があった場合のみ処理
      if (fbWidth != fbBeforeWidth) {
        facebookWrap.html(pagePluginCode(fbWidth)); // ページプラグインのコード変更
        window.FB.XFBML.parse(); // ページプラグインの再読み込み
        fbBeforeWidth = fbWidth; // 今回変更分を保存
      }
    }, 200);
  });
});
